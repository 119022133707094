import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import MarketplaceDashboard from "./pages/MarketplaceDashboard";
import MTADashboard from "./pages/MTADashboard";
import Dashboard from "./pages/Dashboard";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/reports/dashboard" element={<Dashboard />} />
          <Route path="/reports/marketplace-dashboard" element={<MarketplaceDashboard />} />
          <Route path="/reports/mesh-dashboard" element={<MTADashboard />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );  
}

export default App;
