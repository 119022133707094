/* eslint-disable no-console */
import React, { useEffect, useState, useMemo,useCallback,useRef } from "react";

import {
    Box,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    useToast,
    Spinner,
    Text,
    Flex,
    Button,
    Select, FormControl
} from "@chakra-ui/react";
import { AgGridReact } from 'ag-grid-react'
import { useTable, useSortBy, usePagination } from "react-table";
import { api } from "../services/api";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './styles.css'

import { MapProvider } from "./map-context";
import axios from "axios";



const AvatarArenaVotingDashboard = () => {
    const [loading, setLoading] = useState(true);
    const [gridData, setGridData] = useState([]);
    const [tournaments, setTournaments] = useState([]);
    const [selectedTournament, setSelectedTournament] = useState(-1);
    const gridRef = useRef();
    const onBtnExport = useCallback(() => {
        gridRef.current.api.exportDataAsCsv();
      }, []);

    const columnDefs = [
        { headerName: 'Date', field: 'date', width: 220, },
        { headerName: 'Address', field: 'address', width: 300, },
        { headerName: 'Votes', field: 'votes', width: 100 },
    ];
    const handleTournamentChange = (event) => {
        console.log('e', event.target.value)
        setSelectedTournament(event.target.value);

    };
    const defaultColDef = useMemo(() => {
        return {

            sortable: true,
            filter: true,
            unSortIcon: false,
        };
    }, []);
    const getVotingReportByWallet = async () => {
        setLoading(true)
        const rawData = await axios
            .get(
                // eslint-disable-next-line no-undef
                `${process.env.REACT_APP_API_SERVER}/api/account/statistics/report?tournamentId=${selectedTournament}`
            )
        console.log('data', rawData.data)
        let rowData = [];
        await Object.keys(rawData.data.data).forEach(date => {
            rawData.data.data[date].forEach(item => {
                rowData.push({ date, ...item });
            });
        });
        console.log('rowData', rowData)
        setGridData(rowData)
        setLoading(false)
    };
    useEffect(() => {

        const getTournaments = async () => {
            const response = await api.getTournaments(1, 10);
            setTournaments(response.data.data.list)
            setLoading(false)
        }
        getTournaments();
    }, []);



    return (
        <Box minH={"50vh"}>
            {loading ? (
                <Box
                    w="100%"
                    justifyContent="center"
                    alignItems="center"
                    alignContent={"center"}
                    display="block"
                >
                    <Text textAlign="center">
                        Hold your horses, we are loading the data...
                    </Text>
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                        display="block"
                        margin="40px auto"
                    />
                </Box>
            ) : (
                <><div style={{
                    width: "100%", minWidth: '300px', padding: '20px 0px', display: 'flex',
                    flexDirection: 'row'
                }}>
                        <FormControl style={{
                        width:"40%",
                        padding: '0px 20px',

                    }}>
                        <Select value={selectedTournament} onChange={handleTournamentChange}>
                            <option value="-1">Select an option</option>
                            {tournaments.map((tournament) => (
                                <option key={tournament.tournamentId} value={tournament.tournamentId}>
                                    {tournament.name}
                                </option>
                            ))}
                        </Select>

                    </FormControl>
                        <Button onClick={() => getVotingReportByWallet()}>Search</Button>
                        <Button style={{margin:"0px 20px"}} onClick={onBtnExport}>Export CSV</Button>
                </div>


                    <div style={{ minHeight: "50vh" }}>
                        {
                            <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        rowData={gridData}
                                        suppressExcelExport={true}
                                        columnDefs={columnDefs} defaultColDef={defaultColDef} pagination animateRows rowHeight={50} groupHeaderHeight={100} headerHeight={100} theme="ag-theme-alpine" ></AgGridReact>
                            </div>}
                        </div>
                        
                    </>)
                
            }
            

        </Box>
    )
};

export default AvatarArenaVotingDashboard;
