import * as d3 from "d3";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Heading, Box } from "@chakra-ui/react";
function TransactionsByCollection() {
  useEffect(() => {
    getStatCountByCollection();
  }, []);

  const getStatCountByCollection = async () => {
    axios
      .get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_MARKETPLACE_API_SERVER}/api/v1/imx/stat/count/orderBy/collection`

      )
      .then(function (response) {
        if (response.status === 200) {
          if (response.data) {
            renderChart(response.data);
          }
        }
      });
  };
  const renderChart = (data) => {
    const color = d3.scaleOrdinal(d3.schemeCategory10);
    const margin = { top: 100, right: 20, bottom: 30, left: 40 };
    const width = 1200 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    const x = d3.scaleBand().range([0, width]).padding(0.1);
    const y = d3.scaleLinear().range([height, 0]);

    const svg = d3
      .select("#Transactions-By-Collection")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    x.domain(
      data.map(function (d) {
        return d.name;
      })
    );
    y.domain([
      0,
      d3.max(data, function (d) {
        return Number(d.count);
      })
    ]);

    svg
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("fill", function (d, i) {
        return color(i);
      })
      .attr("class", "bar")
      .attr("x", function (d) {
        return x(d.name);
      })
      .attr("width", x.bandwidth())
      .attr("y", function (d) {
        return y(d.count);
      })
      .attr("height", function (d) {
        return height - y(d.count);
      });

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    svg.append("g").call(d3.axisLeft(y));

    let legendArea = svg.append("g").attr("transform", "translate(0, -80)");
    let itemsPerRow = 4; // The number of items you want per row
    let lineHeight = 20; // The height of each line
    let legend = legendArea.selectAll("g")
        .data(data)
        .enter()
        .append("g")
        .attr("transform", function (d, i) {
            let xPosition = (i % itemsPerRow) * 250; // Calculate x position
            let yPosition = Math.floor(i / itemsPerRow) * lineHeight; // Calculate y position
            return "translate(" + xPosition + "," + yPosition + ")";
            
           
        });
        
    legend
      .append("rect")
      .attr("width", 20)
      .attr("height", 20)
      .style("fill", function (d, i) {
        return color(i);
      });

    //添加图例文字
    legend
      .append("text")
      .attr("x", 24)
      .attr("y", 9)
      .style("fill", function (d, i) {
        return color(i);
      })
      .attr("dy", ".35em")
      .text(function (d, i) {
        return d.name + "[" + d.count + "]";
      });
  };

  return (
    <Box>
      <Heading as="h3" size="lg">
        Transactions By Collection
      </Heading>
      <div id="Transactions-By-Collection"></div>
    </Box>
  );
}

export default TransactionsByCollection;
