import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { annotation, annotationCallout } from 'd3-svg-annotation';

const PieChart = ({ data, width, height }) => {
    const ref = useRef();
    const upperSpace = 150
   function desensitization(
    str,
    beginLen = 4,
    endLen = -4,
    fillingLen = 4
  ) {
    if (str === 'null') return 'null';
    if (!str) return '';
    let tempStr = '';
    const len = str.length;
    const firstStr = str.substring(0, beginLen);
    const lastStr = str.substring(len + endLen, len);
    const middleStr = str
      .substring(beginLen, beginLen + fillingLen)
      .replace(/[\s\S]/gi, '.');
    tempStr = firstStr + middleStr + lastStr;
    return tempStr;
  }
  useEffect(() => {
    const pie = d3.pie().value((d) => d.cnt);
    const color = d3.scaleOrdinal(d3.schemeCategory10);
    const arc = d3.arc().innerRadius(0).outerRadius(Math.min(width, height) / 2);
    const labelArc = d3.arc().innerRadius(Math.min(width, height) / 2.5).outerRadius(Math.min(width, height) / 2.5);
    const svg = d3.select(ref.current).attr('width', width).attr('height', height);
    const g = svg.append('g').attr('transform', `translate(${width / 2}, ${height / 2})`);

    const pieData = pie(data);

    g.selectAll('path')
      .data(pieData)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d) => color(d.data.wallet))
      .attr('stroke', 'white')
      .attr('stroke-width', '2px');

    g.selectAll('text')
      .data(pieData)
      .enter()
      .append('text')
      .attr('transform', (d) => `translate(${labelArc.centroid(d)})`)
      .text((d) =>desensitization(d.data.wallet))
      .attr('fill', 'black')
      .attr('font-size', '12px')
      .attr('text-anchor', 'middle');
  }, [data, width, height]);

    return (
        <div style={{margin:'20px'}}>
             <svg ref={ref}></svg>
      </div>
   
  );
};

export default PieChart;
