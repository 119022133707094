import * as d3 from "d3";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Heading, Box } from "@chakra-ui/react";

const data_legend = [
  {
    name: "A",
    color: "#4a9b5b"
  },
  {
    name: "B",
    color: "#5caeb9"
  }
];
function TransactionsByType() {
  useEffect(() => {
    getStatCountByType();
  }, []);

  const getStatCountByType = async () => {
    axios
      .get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_MARKETPLACE_API_SERVER}/api/v1/imx/stat/count/orderBy/type`
      )
      .then(function (response) {
        if (response.status === 200) {
          if (response.data) {
            renderChart(response.data);
          }
        }
      });
  };
  const renderChart = (data) => {
    const color = d3.scaleOrdinal(d3.schemeCategory10);
    const margin = { top: 60, right: 20, bottom: 30, left: 40 };
    const width = 400 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    const x = d3.scaleBand().range([0, width]).padding(0.1);
    const y = d3.scaleLinear().range([height, 0]);

    const svg = d3
      .select("#Transactions-By-Type")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    x.domain(
      data.map(function (d) {
        return d.transaction_event;
      })
    );
    y.domain([
      0,
      d3.max(data, function (d) {
        return Number(d.count);
      })
    ]);

    svg
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("fill", function (d, i) {
        return color(i);
      })
      .attr("class", "bar")
      .attr("x", function (d) {
        return x(d.transaction_event);
      })
      .attr("width", x.bandwidth())
      .attr("y", function (d) {
        return y(d.count);
      })
      .attr("height", function (d) {
        return height - y(d.count);
      });

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    svg.append("g").call(d3.axisLeft(y));

    let legendArea = svg.append("g").attr("transform", "translate(0, -40)");

    let legend = legendArea
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", function (d, i) {
        return "translate(" + i * 120 + ",0)";
      });
    legend
      .append("rect")
      .attr("width", 20)
      .attr("height", 20)
      .style("fill", function (d, i) {
        return color(i);
      });

    //添加图例文字
    legend
      .append("text")
      .attr("x", 24)
      .attr("y", 9)
      .style("fill", function (d, i) {
        return color(i);
      })
      .attr("dy", ".35em")
      .text(function (d, i) {
        return d.transaction_event + "[" + d.count + "]";
      });
  };

  return (
    <Box>
      <Heading as="h3" size="lg">
        Transactions By Type
      </Heading>
      <div id="Transactions-By-Type"></div>
    </Box>
  );
}

export default TransactionsByType;
