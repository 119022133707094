/* eslint-disable no-console */
import React, { useEffect, useState, useMemo } from "react";

import {
  Box,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  useToast,
  Spinner,
  Text,
  Flex,
} from "@chakra-ui/react";

import { useTable, useSortBy, usePagination } from "react-table";

import { MapProvider } from "./map-context";
import axios from "axios";
import PieChart from './Piechart';

export function SortIcon({ className }) {
  return (
    <svg
      className={className}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 320 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"></path>
    </svg>
  );
}

export function SortUpIcon({ className }) {
  return (
    <svg
      className={className}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 320 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path>
    </svg>
  );
}

export function SortDownIcon({ className }) {
  return (
    <svg
      className={className}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 320 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path>
    </svg>
  );
}

export function LocateCell({ value, column, row }) {
  return (
    <>
      <MapProvider>
        <MarkerButton pos={row.original[column.posAccessor]} />
      </MapProvider>
    </>
  );
}

const AvatarArenaVotingDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [reportList, setReportList] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "Wallet",
        accessor: "wallet",
      },
      {
        Header: "Times Voted",
        accessor: "cnt",
      },
    ],
    []
  );

  const data = useMemo(() => reportList);

  useEffect(() => {
    getVotingReportByWallet();
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy,
      usePagination
    );

  const getVotingReportByWallet = async () => {
    axios
      .get(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_API_SERVER}/api/account/statistics/voted`
      )
      .then(function (response) {
        // handle success
        setReportList(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  return (
    <Box minH={"50vh"}>
      {loading ? (
        <Box
          w="100%"
          justifyContent="center"
          alignItems="center"
          alignContent={"center"}
          display="block"
        >
          <Text textAlign="center">
            Hold your horses, we are loading the data...
          </Text>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            display="block"
            margin="40px auto"
          />
        </Box>
      ) : (
          reportList.length && (
            <Box>
            <Text textAlign="left">
            Votes by wallet
          </Text>
                <PieChart data={reportList} width={600} height={400} />
             
              <TableContainer>
            <Table {...getTableProps()}>
              <Thead>
                {headerGroups.map((headerGroup) => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <Flex gap="2">
                          {column.render("Header")}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <SortDownIcon />
                            ) : (
                              <SortUpIcon />
                            )
                          ) : (
                            <SortIcon />
                          )}
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <Td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        )
      )}
    </Box>
  );
};

export default AvatarArenaVotingDashboard;
