import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Heading, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { userHasAccess } from "@impact-theory/app-auth";
import AvatarArenaDashboard from "../components/AvatarArenaDashboard";
import AvatarArenaVotingDashboard from "../components/AvatarArenaVotingDashboard";
import AvatarArenaVotedDashboard from '../components/AvatarArenaVotedDashboard'

function Dashboard() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    async function run() {
      const result = await userHasAccess();
      setLoggedIn(result);
    }
    run();
  }, []);

  if (!loggedIn)
    return (
      <Layout>
        <Heading marginBottom="2rem">No Permission</Heading>
      </Layout>
    );

  return (
    <Layout>
      <Heading marginBottom="2rem">Avatar Arena Dashboard</Heading>
      <Tabs>
        <TabList>
          <Tab>Dashboard</Tab>
          <Tab>Voting By Wallet</Tab>
          <Tab>Daily Voting Table</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AvatarArenaDashboard />
          </TabPanel>
          <TabPanel>
            <AvatarArenaVotingDashboard />
          </TabPanel>
          <TabPanel>
            <AvatarArenaVotedDashboard />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Layout>
  );
}

export default Dashboard;
